import { useQuery } from '@apollo/client'
import Alert from 'components/feedback/Alert'
import { currentUserQuery } from 'lib/queries'
import React, { createContext, useContext } from 'react'

const UserContext = createContext({})

export const UserProvider = ({ children }) => {
  const { data, loading, error, refetch } = useQuery(currentUserQuery)
  const currentUser = data && data.viewer

  if (error) {
    return (
      <Alert
        className="m-4"
        title="Error loading user"
        type="error"
        showIcon
        description={error.message}
      />
    )
  }

  return (
    <UserContext.Provider value={{ loading, currentUser, refetch }}>
      {children}
    </UserContext.Provider>
  )
}

export const useCurrentUser = () => {
  return useContext(UserContext)
}

export default UserContext
