import env from './env'

export const memberUrl = (group, userId = '', user = '') => {
  let path = ''

  if (group && group.slug === 'bptn-networking') {
    path = `/members` // @TODO: get value of feature flag instead?
  } else if (user.mentor === false) {
    path = `/mentees`
  } else {
    path = `/mentors`
  }

  if (userId) {
    path = `${path}/${userId}`
  }
  return path
}

export const profileUrl = (user, locale = 'en') => {
  if (!user) return window.location.href

  if (!('mentor' in user)) {
    console.error('no mentor field on user object, url will resolve to mentee')
  }
  if (!('slug' in user) && !('id' in user)) {
    console.error('user object requires slug or id to resolve a url')
    return window.location.href
  }

  const userUrl = user.slug || user.id

  if (user.mentor) {
    return `/${locale}${memberUrl(user.group, userUrl)}`
  }
  return `/${locale}/mentees/${userUrl}`
}

export const groupHost = (group) => {
  if (!group) {
    return env.groupDomain('marketplace')
  }

  if (group.customDomain) {
    return `https://${group.customDomain}`
  }

  if (group.slug) {
    return env.groupDomain(group.slug)
  }

  return env.clientDomain
}

export const baseUrl = (group, locale) => {
  return `${groupHost(group)}/${locale}`
}

export const conferenceUrl = ({ booking, group, user, locale = 'en' }) => {
  if (!group) throw new Error('conferenceUrl needs a group')

  const provider = group?.meetingProvider
  if (provider === 'twilio' && !user?.token) {
    throw new Error('groups using twilio need a user token for conferenceUrl')
  }

  switch (provider) {
    case 'jitsi_external':
      return `https://meet.jit.si/${booking.id}`
    case 'jitsi_internal':
      return `/${locale}/conferences/${booking.id}/jitsi`
    case 'twilio':
      if (!user?.token) {
        throw new Error('user object is missing token')
      } else {
        const token = user?.token?.split('.').join('/')
        return `${env.legacyDomain}/conference/${booking.id}/${token}`
      }
    default:
      throw new Error(`${provider} is not a recognized provider`)
  }
}

export const sessionUrl = (booking, locale, group = null) => {
  const sessionGroup = group || booking.group
  return `${baseUrl(sessionGroup, locale)}/sessions/${booking.id}`
}

export const sessionIndexUrl = (locale, isDashboard) => {
  const suffix = isDashboard ? 'dashboard/sessions' : 'personal'
  return `/${locale}/${suffix}`
}

export const sessionsHome = (group, locale, isDashboard) => {
  const path = isDashboard ? 'dashboard/sessions' : 'personal'
  return `${baseUrl(group, locale)}/${path}`
}

export const sessionEdit = (booking, locale, isDashboard) => {
  const path = sessionsHome(booking.group, locale, isDashboard)
  return `${path}/edit?id=${booking.id}`
}
