let env = 'development'

if (process.env.NEXT_PUBLIC_BASE_URL === 'https://mentorly.co') {
  env = 'production'
}

if (process.env.NEXT_PUBLIC_BASE_URL === 'https://mentorly.dev') {
  env = 'staging'
}

const stagingGroupRegex = /(.*)\.(.*)\.mentorly\.dev/

const stagingGroupDomain = (slug) => {
  if (window.location) {
    const matches = window.location.host.match(stagingGroupRegex)

    if (matches) {
      return `https://${slug}.${matches[2]}.mentorly.dev`
    }
  }

  return `https://${slug}.mentorly.dev`
}

const envConfig = {
  development: {
    development: true,
    env: 'development',
    clientDomain: 'http://localhost:3010',
    legacyDomain: 'http://localhost:3030',
    cookieDomain: null,
    groupDomain: (slug) => `http://${slug}.localtest.me:3010`,
    groupRegex: /(.*)\.localtest\.me:3010/,
    mainHost: 'localhost:3010',
    tokenName: 'token',
    logRocketID: 'nq434y/staging-ziuka',
    intercomAppId: 'jh99prcy',
  },
  staging: {
    staging: true,
    env: 'development',
    clientDomain: 'https://mentorly.dev',
    legacyDomain: 'https://mentorly.dev',
    cookieDomain: 'mentorly.dev',
    groupDomain: stagingGroupDomain,
    groupRegex: stagingGroupRegex,
    mainHost: 'mentorly.dev',
    tokenName: 'staging-token',
    logRocketID: 'nq434y/staging-ziuka',
    intercomAppId: 'jh99prcy',
  },
  production: {
    production: true,
    env: 'production',
    clientDomain: 'https://mentorly.co',
    legacyDomain: 'https://mentorly.co',
    cookieDomain: 'mentorly.co',
    groupDomain: (slug) => `https://${slug}.mentorly.co`,
    groupRegex: /(.*)\.mentorly\.co/,
    mainHost: 'mentorly.co',
    tokenName: 'token',
    logRocketID: 'nq434y/production',
    intercomAppId: 'xvy5mqyt',
  },
}

export default envConfig[env]
