import { useCurrentGroup } from 'lib/GroupContext'
import { get } from 'lodash'

import config from '../config/features.json'

export const getFeatureFlag = (group, id) => {
  if (group) {
    return get(config, [group.slug, id], get(config, ['default', id], false))
  } else {
    return get(config, ['default', id], false)
  }
}

export const useFeatureFlag = (id) => {
  const { currentGroup, loading } = useCurrentGroup()

  if (loading) {
    return false
  }

  return getFeatureFlag(currentGroup, id)
}

const Feature = ({ id, group, children }) => {
  const { currentGroup, loading } = useCurrentGroup()

  if (loading) {
    return null
  }

  const active = getFeatureFlag(currentGroup || group, id)

  if (active) {
    return children
  } else {
    return null
  }
}

export default Feature
